<template>
  <v-container
    id="operation-list"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-cog-outline"
      :title="$t('sidebar.list')"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <!-- Ajouter un messages (Seulement pour le rôle 'admin')-->
        <v-col
          class="text-right ml-4"
          cols="12"
        >
          <v-btn
            color="success"
            @click="addItem"
          >
            {{ $t('Add') }}
            <v-icon
              right
              dark
            >
              mdi-plus-circle-outline
            </v-icon>
          </v-btn>
        </v-col>

        <v-data-table
          :headers="tableHeaders"
          :items="table"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :search="search"
          item-key="id"
          @page-count="pageCount = $event"
        >
          <!-- Search-->
          <template #top>
            <v-text-field
              v-model="search"
              :label="$t('search')"
              class="mx-4"
            />
          </template>

          <!-- Actif-->
          <template
            slot="item.actif"
            slot-scope="props"
          >
            <v-icon
              v-if="props.item.actif"
              color="primary"
            >
              mdi-check
            </v-icon>
          </template>

          <!-- Action-->
          <template
            slot="item.action"
            slot-scope="props"
          >
            <!-- Edit-->
            <v-btn
              fab
              dark
              x-small
              color="success"
              :title="$t('Edit')"
              @click="editItem(props.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <!--  Delete (Seulement pour le rôle 'admin')-->
            <!-- <v-btn
              class="ml-2"
              fab
              dark
              x-small
              color="red"
              :title="$t('Delete')"
              @click="deleteItem(props.item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          />
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <!-- <v-text-field
                :value="itemsPerPage"
                :label="$t('table.Items_per_page')"
                type="number"
                min="-1"
                max="15"
                @input="itemsPerPage = parseInt($event, 10)"
              /> -->
              <v-select
                v-model="itemsPerPage"
                :items="itemsPage"
                :label="$t('table.Items_per_page')"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="submitForm">
        <validation-observer
          ref="obs"
          v-slot="{ handleSubmit }"
        >
          <form>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ submitType === 'edit' ? $t('Edit') : $t('Add') }} {{ $t('messages.a_message') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- Nom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Name') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Name')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.nom"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Item-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Item
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Item"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.item"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Valeur en Français-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('list.French_value') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Value')"
                  rules="required"
                >
                  <v-textarea
                    v-model="submitForm.fr"
                    auto-grow
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Valeur en Anglais-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('list.English_value') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Value')"
                  rules="required"
                >
                  <v-textarea
                    v-model="submitForm.en"
                    auto-grow
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Actif-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Active') }}
              </v-col>

              <v-col cols="8">
                <v-checkbox
                  v-model="submitForm.actif"
                />
              </v-col>
            </v-row>

            <div class="pa-3 text-center">
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="dialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Services
  import ListService from '@/services/01Cell/ListService'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import Swal from 'sweetalert2'

  export default {
    name: 'OperationList',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        search: '',
        submitForm: {},
        submitType: null,
        table: [],
        tableHeaders: [
          { text: this.$i18n.t('Name'), value: 'nom', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Item', value: 'item', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('list.French_value'), value: 'fr', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('list.English_value'), value: 'en', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Active'), value: 'actif', sortable: true, align: 'end', class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'end', class: 'text-h4 primary--text', width: 150 },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        itemsPage: [5, 10, 25, 50, 100],
        dialog: false,
      }
    },

    mounted () {
      this.getMessages()
    },

    methods: {
      async getMessages () {
        // Start Spinner
        this.$loading.show()
        try {
          this.table = await ListService.list()
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async editItem (item) {
        // Start Spinner
        this.$loading.show()
        try {
          // Edit
          this.submitType = 'edit'
          // Paramètre
          this.submitForm = await ListService.view(item.id)
          // Show dialog
          this.dialog = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      addItem () {
        // Ajout
        this.submitType = 'add'
        // Reset du submit
        this.submitForm = {
          actif: true,
        }
        // Show dialog
        this.dialog = true
      },
      deleteItem (id) {
        // SweetAlert
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('list.delete')} ?`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: this.$i18n.t('Yes_delete_it'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            // Start Loader
            this.$loading.show()
            try {
              await ListService.remove(id)
              // On va rechercher les messages
              this.getMessages()
            } catch (err) {
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      async submit () {
        // Hide dialog
        this.dialog = false

        // Start Spinner
        this.$loading.show()
        try {
          if (this.submitType === 'edit') {
            await ListService.update(this.submitForm, this.submitForm.id)
          } else {
            await ListService.save(this.submitForm)
          }
          // On va rechercher les paramètres
          this.getMessages()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
